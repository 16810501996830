import { modalBoxStyle } from '@c/Settings/LogoutModal';
import { Modal, Box, useMediaQuery, Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { theme } from '../../../theme/theme';
import usePersistedStorage from '../../../shared/hooks/usePersistedStorage';
import Typography2 from '@c/Typographies/Typography2';
import { setUserTown } from '../../../utils/user.utils';
import CityDropdownBase from '@c/Pages/Sexmap/CityDropdownBase';
import { useScrollLock } from '../../../shared/hooks/useScrollLock.hook';
import ModalHeader from '@c/ModalComponents/ModalHeader/ModalHeader';
import { useRouter } from 'next/router';

const SelectCityModal: React.FC = () => {
  const [opened, setOpened] = useState(false);
  const [cityLocal, setCityLocal] = useState(null);
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  useScrollLock(opened);

  const [city] = usePersistedStorage('city');

  const shouldOpenSelectCityDistrict = useMemo(() => {
    return !city;
  }, [city]);

  useEffect(() => {
    if (shouldOpenSelectCityDistrict && !city) {
      setOpened(true);
    }
  }, [shouldOpenSelectCityDistrict]);

  const router = useRouter();

  const handleSaveCity = () => {
    setUserTown(cityLocal);
    router.replace(
      `${process.env.NEXT_PUBLIC_FRONTEND_URL}/girls/normal/${cityLocal}`,
    );
    setOpened(false);
  };

  return (
    <Modal
      open={opened}
      onClose={() => {
        setOpened(false);
      }}
    >
      <Box sx={modalBoxStyle} style={{ width: '100%', maxWidth: '300px' }}>
        <div className={styles.content}>
          <ModalHeader
            title="Выберите Город"
            onClose={() => setOpened(false)}
          />
          <CityDropdownBase city={city} onChange={setCityLocal} />
          <Button variant="contained" onClick={handleSaveCity}>
            Выбрать
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default SelectCityModal;
