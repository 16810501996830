import React from 'react';
import Image from 'next/image';
import Typography2 from '@c/Typographies/Typography2';
import styles from './styles.module.scss';
import Instagram from '@mui/icons-material/Instagram';
import Telegram from '@mui/icons-material/Telegram';
import TravelExplore from '@mui/icons-material/TravelExplore';
import { theme } from 'theme/theme';
import { useBaseUrl } from 'utils/user.utils';
import Link from 'next/link';
import dynamic from 'next/dynamic';

const BlogRecom = dynamic(() => import('@c/Blog/BlogRecom'), {
  ssr: false,
});

const CommonFooter = () => {
  const { getRedirectLink } = useBaseUrl();

  return (
    <footer
      className={styles.footer}
      style={{
        backgroundColor: '#8b6ab7',
      }}
    >
      <div className={styles.footer_container}>
        <div className={styles.footer_top_container}>
          <div>
            <div className={styles.image_container}>
              <Image
                src="/logo_footer.svg"
                fill
                alt="Logo Footer"
                sizes="100vw"
                style={{
                  objectFit: 'contain',
                }}
              />
            </div>
            <Typography2
              weight="400"
              size="m"
              color={theme.palette.secondary.main}
            >
              (с) 2023 сервис №1 в России в сфере интим услуг
            </Typography2>
            <BlogRecom />
          </div>
          <div className={styles.link_container}>
            <Typography2
              weight="600"
              size="l"
              color={theme.palette.secondary.main}
            >
              Девушки
            </Typography2>
            <div className={styles.links_cont}>
              <Link href={getRedirectLink('', ['indi'])}>
                <Typography2
                  weight="400"
                  size="m"
                  color={theme.palette.secondary.main}
                  className={styles.link}
                >
                  Индивидуалки
                </Typography2>
              </Link>
              <Link href={getRedirectLink('normal')}>
                <Typography2
                  weight="400"
                  size="m"
                  color={theme.palette.secondary.main}
                  className={styles.link}
                >
                  Проститутки
                </Typography2>
              </Link>
              <Link href={getRedirectLink('elite')}>
                <Typography2
                  weight="400"
                  size="m"
                  color={theme.palette.secondary.main}
                  className={styles.link}
                >
                  Элитные
                </Typography2>
              </Link>
              <Link href={getRedirectLink('massage')}>
                <Typography2
                  weight="400"
                  size="m"
                  color={theme.palette.secondary.main}
                  className={styles.link}
                >
                  Массажистки
                </Typography2>
              </Link>
              <Link href={getRedirectLink('bdsm')}>
                <Typography2
                  weight="400"
                  size="m"
                  color={theme.palette.secondary.main}
                  className={styles.link}
                >
                  БДСМ
                </Typography2>
              </Link>
            </div>
          </div>
          <div className={styles.link_container}>
            <Typography2
              weight="600"
              size="l"
              color={theme.palette.secondary.main}
            >
              Ссылки
            </Typography2>
            <div className={styles.links_cont}>
              <Link href="/about">
                <Typography2
                  weight="400"
                  size="m"
                  color={theme.palette.secondary.main}
                  className={styles.link}
                >
                  О Нас
                </Typography2>
              </Link>
              <Link href="/sexmap">
                <Typography2
                  weight="400"
                  size="m"
                  base="h4"
                  className={styles.link + ' ' + styles.icon_link}
                  color={theme.palette.secondary.main}
                >
                  <TravelExplore
                    color="secondary"
                    style={{ width: '20px', height: '20px' }}
                  />
                  Карта
                </Typography2>
              </Link>
              {/* <Typography2
                weight="400"
                size="m"
                color={theme.palette.secondary.main}
              >
                Контакты
              </Typography2> */}
              <Link href="/agreement">
                <Typography2
                  weight="400"
                  size="m"
                  color={theme.palette.secondary.main}
                  className={styles.link}
                >
                  Польз. Соглашение
                </Typography2>
              </Link>
            </div>
          </div>
          {/* <div>
            <Typography2
              weight="500"
              size="l"
              className={styles.account_header}
              color={theme.palette.secondary.main}
            >
              Наши Аккаунты
            </Typography2>
            <div className={styles.social_containers}>
              <div className={styles.social_container}>
                <Telegram
                  style={{ width: '24px', height: '24px' }}
                  className={styles.socialIconBase}
                />
                <Typography2
                  weight="400"
                  size="m"
                  className={styles.social_text}
                  color={theme.palette.secondary.main}
                >
                  Telegram
                </Typography2>
              </div>
              <div className={styles.social_container}>
                <Instagram
                  style={{ width: '24px', height: '24px' }}
                  className={styles.socialIconBase}
                />
                <Typography2
                  weight="400"
                  size="m"
                  className={styles.social_text}
                  color={theme.palette.secondary.main}
                >
                  Instagram
                </Typography2>
              </div>
            </div>
          </div> */}
        </div>
        <div
          className={styles.bottom_text_container}
          style={{
            borderTop: '1px solid' + theme.palette.secondary.main,
          }}
        >
          <Typography2
            weight="400"
            size="s"
            color={theme.palette.secondary.main}
          >
            Связь с администрацией возможна ТОЛЬКО через личный кабинет, любые
            сообщения в мессенджерах от нашего лица присылают мошенники
          </Typography2>
        </div>
      </div>
    </footer>
  );
};

export default CommonFooter;
