import { modalBoxStyle } from '@c/Settings/LogoutModal';
import Typography2 from '@c/Typographies/Typography2';
import { Modal, Box } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import NoAdultContentIcon from '@mui/icons-material/NoAdultContent';

const OverageModal: React.FC = () => {
  const [opened, setOpened] = useState(false);

  const getAgreed = () => {
    const agreed = localStorage.getItem('over');
    return agreed;
  };

  const onAgree = () => {
    localStorage.setItem('over', 'yes');
    setOpened(false);
  };

  const onDisagree = () => {
    window.location.href = 'https://google.com';
  };

  useEffect(() => {
    const agreed = getAgreed();
    if (agreed !== 'yes') {
      setOpened(true);
    }
  }, []);

  return (
    <Modal
      open={opened}
      onClose={(_, reason: string) => {
        if (reason !== 'backdropClick') {
          setOpened(false);
        }
      }}
    >
      <Box sx={modalBoxStyle} style={{ width: '100%', maxWidth: '300px' }}>
        <div className={styles.content}>
          <Typography2 size="l" weight="500">
            Что бы продолжить вы должны подтвердить, что вам больше 18ти лет
          </Typography2>
          <div className={styles.icon_row}>
            <NoAdultContentIcon
              color="primary"
              style={{ width: 130, height: 130 }}
            />
          </div>
          <div className={styles.button_row}>
            <Button variant="outlined" onClick={onDisagree}>
              Уйти
            </Button>
            <Button variant="contained" onClick={onAgree}>
              Мне больше 18ти
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default OverageModal;
