import type { NextPage } from 'next';
import Head from 'next/head';
import HeaderRedesigned from '@c/HeaderComponents/HeaderRedesigned';
import styles from './styles.module.scss';
import Image from 'next/image';
import CommonFooter from '@c/Pages/Common/Footer';
import { theme } from 'theme/theme';
import { isUserLoggedIn, useBaseUrl } from 'utils/user.utils';
import { useDispatch } from 'react-redux';
import { toggleAuthModal } from 'store/actions/user.actions';
import { getMainPageData } from 'api/girl.service';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Typography2 from '@c/Typographies/Typography2';
import { latinize } from '../shared/search.utils';
import Link from 'next/link';
import { useEffect } from 'react';
import { makeLogout } from '../utils/user.utils';
import Button from '@mui/material/Button';
import CardVerticalSkeleton from '@c/GirlComponents/Cards/CardVertical/Skeleton';
import ModalGroupWrapper from '@c/Dialogs/ModalGroupWrapper';
const OverageModal = dynamic(() => import('@c/Dialogs/OverageModal'), {
  ssr: false,
});
// const Button = dynamic(() => import('@mui/material/Button'), {
//   ssr: false,
// });

const GirlCard = dynamic(() => import('@c/GirlComponents/Cards/CardVertical'), {
  ssr: false,
  suspense: true,
  loading: () => <CardVerticalSkeleton />,
});

// const Button = dynamic(() => import('@mui/material/Button'), { ssr: false });

export async function getServerSideProps(context) {
  try {
    const data = await getMainPageData();
    return {
      props: data,
    };
  } catch {
    return {
      props: {
        monthly: {},
        recommended: [],
        popular: [],
        recent: [],
        masseuses: [],
      },
    };
  }
}

type Props = {
  monthly: any;
  recommended: any;
  popular: any;
  recent: any;
  masseuses: any;
};

const Home: NextPage<Props> = ({
  monthly,
  recommended,
  popular,
  recent,
  masseuses,
}) => {
  const router = useRouter();
  const isLoggedIn = isUserLoggedIn();
  const dispatch = useDispatch();

  useEffect(() => {
    if (router?.query?.force_logout) {
      router.replace('/');
      makeLogout(router, dispatch, true);
    }
  }, [router.query]);

  const handleCreateNew = () => {
    const redirectUrl = '/profile/managing?create';
    if (!isLoggedIn) {
      dispatch(toggleAuthModal());
      router.push({
        pathname: router.pathname,
        query: { ...router.query, redirect: redirectUrl },
      });
    } else {
      router.push(redirectUrl);
    }
  };
  const { getRedirectLink } = useBaseUrl();

  return (
    <div>
      <Head>
        <title>
          Секспросвет - Проститутки и Индивидуалки. Проверенные, реальные
          девушки с отзывами и настоящими фотографиями. Снять проститутку{' '}
        </title>
        {/* Проверенные, реальные девушки с отзывами и настоящими фотографиями. Снять проститутку */}
        <meta
          name="description"
          content={
            'Проститутки и Индивидуалки. Проверенные, реальные девушки с отзывами и настоящими фотографиями. Снять проститутку'
          }
        />
      </Head>
      <HeaderRedesigned />
      <ModalGroupWrapper children={undefined} />
      <section className={styles.container}>
        <div className={styles.header}>
          <div className={styles.header_inner}>
            <div className={styles.right_container}>
              <Typography2
                weight="600"
                size="xxxl"
                base="h1"
                className={styles.text + ' ' + styles.heading_text}
              >
                Секспросвет - сервис №1 в России в сфере интим услуг
              </Typography2>
              <Typography2
                weight="400"
                size="xxl"
                base="h2"
                className={styles.text + ' ' + styles.description_text}
              >
                Больше не надо искать
              </Typography2>
              <div className={styles.button_container}>
                <Link href="/girls/all">
                  <Button variant="contained" size="large">
                    Выбрать Девушку
                  </Button>
                </Link>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleCreateNew}
                >
                  Добавить Анкету
                </Button>
              </div>
            </div>
            <Link
              target="_blank"
              prefetch={false}
              className={styles.image_container}
              href={getRedirectLink(
                monthly.type,
                [`${monthly.id}-${latinize(monthly.name)}`], //latinize(girl.locationUnit),
                '',
                monthly.locationCity,
              )}
            >
              <div className={styles.girl_of_month_title}>
                <Typography2 weight="700" size="xl" color={'#fff'} base="h4">
                  Девушка Месяца
                </Typography2>
              </div>
              <Image
                priority={true}
                loading="eager"
                alt="Девушка Месяца"
                src={
                  process.env.NEXT_PUBLIC_BACKEND_URL +
                  '/' +
                  monthly?.media
                    ?.sort((mediaA, mediaB) => mediaA.order - mediaB.order)
                    ?.find((media) => media.type === 'image')?.path
                }
                placeholder="blur"
                blurDataURL="/map.png"
                fill
                sizes="100vw"
                style={{
                  borderRadius: '6px',
                  objectFit: 'cover',
                }}
              />
              <div className={styles.name_container}>
                <Typography2 weight="700" size="xxl" color={'#fff'}>
                  {monthly?.name}, {monthly?.age}
                </Typography2>
              </div>
            </Link>
          </div>
        </div>
        <section className={styles.popular}>
          <div className={styles.inner}>
            <Link href={getRedirectLink('normal')} prefetch={false}>
              <Typography2 weight="700" size="xxl" className={styles.link}>
                Проститутки
              </Typography2>
            </Link>
            <div className={styles.popular_inner_container}>
              <div className={styles.popular_girls_container}>
                {popular?.slice(0, 3).map((girl) => (
                  <GirlCard girl={girl} key={girl.id} />
                ))}
                <div className={styles.text_cont}>
                  <Typography2 weight="500" size="xl">
                    Здесь Вы найдете самых горячих девушек города
                  </Typography2>
                  <Typography2 weight="400" size="l">
                    На сайте представленна проверенная информация о ближайших к
                    вам девушках
                  </Typography2>
                  <Link href={getRedirectLink()} prefetch={false}>
                    <Button variant="contained">Выбрать Девушку</Button>
                  </Link>
                </div>
                {popular?.slice(3).map((girl) => (
                  <GirlCard girl={girl} key={girl.id} />
                ))}
              </div>
            </div>
          </div>
        </section>
        <div
          className={styles.user}
          style={{ backgroundColor: theme.palette.lightPurple.main }}
        >
          <div className={styles.user_inner}>
            <Typography2 weight="500" size="xl" color="white">
              Создайте анкету за 1 минуту
            </Typography2>
            <div>
              <Button
                variant="contained"
                size="large"
                onClick={handleCreateNew}
              >
                Добавить Анкету
              </Button>
            </div>
          </div>
        </div>
        <section className={styles.recommended}>
          <div className={styles.inner}>
            <Typography2 weight="700" size="xxl">
              Популярные
            </Typography2>
            <div className={styles.popular_inner_container}>
              <div className={styles.popular_girls_container}>
                {popular?.slice(0, 5).map((girl) => (
                  <GirlCard girl={girl} key={girl.id} />
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className={styles.recommended}>
          <div className={styles.inner}>
            <Link href={getRedirectLink('normal')} prefetch={false}>
              <Typography2 weight="700" size="xxl" className={styles.link}>
                Новенькие
              </Typography2>
            </Link>
            <div className={styles.popular_inner_container}>
              <div className={styles.popular_girls_container}>
                {recent?.map((girl) => (
                  <GirlCard girl={girl} key={girl.id} />
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className={styles.recommended}>
          <div className={styles.inner}>
            <Link href={getRedirectLink('massage')} prefetch={false}>
              <Typography2 weight="700" size="xxl" className={styles.link}>
                Массажистки
              </Typography2>
            </Link>
            <div className={styles.popular_inner_container}>
              <div className={styles.popular_girls_container}>
                {masseuses?.map((girl) => (
                  <GirlCard girl={girl} key={girl.id} />
                ))}
              </div>
            </div>
          </div>
        </section>
      </section>
      <CommonFooter />
    </div>
  );
};

export default Home;
