import { useEffect, useMemo, useState } from 'react';

const usePersistedStorage = (name: string) => {
  const [state, setState] = useState();

  const serialize = (value: any) => {
    return JSON.stringify(value);
  };

  const unserialize = (serialized_value: string) => {
    try {
      return JSON.parse(serialized_value);
    } catch {
      return serialized_value;
    }
  };

  useEffect(() => {}, []);

  const handleUpdateState = (value: any) => {
    const serialized = serialize(value);
    try {
      if (window !== undefined) {
        localStorage.setItem(name, serialized);
        setState(value);
      }
    } catch {
      console.log('window not defined');
    }
  };

  const value = useMemo(() => {
    try {
      if (window !== undefined) {
        return unserialize(localStorage.getItem(name));
      } else {
        return null;
      }
    } catch {
      console.log('window not defined');
      return null;
    }
  }, [state]);

  return [value, handleUpdateState];
};

export default usePersistedStorage;
