import React, { useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import cities from '../../../../data/cities';

type Props = {
  city: string;
  onChange: (arg1: string) => void;
};

const CityDropdownBase: React.FC<Props> = ({ city, onChange }) => {
  return (
    <Autocomplete
      options={cities}
      groupBy={(option) => {
        return ['Москва', 'Московская область', 'Санкт-Петербург'].includes(
          option,
        )
          ? 'Основные'
          : 'Другие';
      }}
      value={city}
      onChange={(_, val) => onChange(val)}
      size="small"
      sx={{
        backgroundColor: 'white',
        borderRadius: '6px',
        border: '1px solid rgba(0, 0, 0, 0.5)',
      }}
      renderInput={(props) => <TextField {...props} />}
    />
  );
};

export default CityDropdownBase;
