import { Skeleton } from '@mui/material';
import React from 'react';
import styles from './styles.module.css';

const VerticalSkeleton = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative',
        backgroundColor: 'lightgray',
        width: '100%',
        height: '100%',
      }}
      className={styles.cardContainer}
    >
      <Skeleton variant="rectangular" width="100%" height="21.5%"></Skeleton>
    </div>
  );
};

export default VerticalSkeleton;
