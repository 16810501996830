import React from 'react';
import { useMediaQuery } from '@mui/material';
import { theme } from '../../../theme/theme';
import OverageModal from '../OverageModal';
import SelectCityModal from '../SelectCityModal';

const ModalGroupWrapper = ({ children }) => {
  return (
    <>
      <OverageModal />
      <SelectCityModal />
      {children}
    </>
  );
};

export default ModalGroupWrapper;
