const cities = [
  'Москва',
  'Московская область',
  'Санкт-Петербург',
  'Владивосток',
  'Волгоград',
  'Воронеж',
  'Екатеринбург',
  'Ижевск',
  'Казань',
  'Краснодар',
  'Красноярск',
  'Нижний Новгород',
  'Новосибирск',
  'Омск',
  'Пермь',
  'Ростов-на-Дону',
  'Самара',
  'Саратов',
  'Сочи',
  'Тольятти',
  'Тюмень',
  'Уфа',
  'Челябинск',
  'Южно-Сахалинск',

  // ...[
  //   'Новосибирск',
  //   'Екатеринбург',
  //   'Казань',
  //   'Нижний Новгород',
  //   'Челябинск',
  //   'Самара',
  //   'Омск',
  //   'Ростов-на-Дону',
  //   'Уфа',
  //   'Пермь',
  //   'Красноярск',
  //   'Воронеж',
  //   'Волгоград',
  //   'Краснодар',
  //   'Саратов',
  //   'Тюмень',
  //   'Тольятти',
  //   'Ижевск',
  //   'Сочи',
  //   'Владивосток',
  //   'Южно-Сахалинск',
  // ].sort((a, b) => a.localeCompare(b)),
];

export const cityToCoords = {
  Москва: [55.7540471, 37.620405],
  'Московская область': [55.7540471, 37.620405],
  'Санкт-Петербург': [59.9391313, 30.3159004],
  Новосибирск: [55.028191, 82.9211489],
  Екатеринбург: [56.8385216, 60.6054911],
  Казань: [55.7943584, 49.1114975],
  'Нижний Новгород': [56.3240627, 44.0053913],
  Челябинск: [55.1602624, 61.4008078],
  Самара: [53.1950306, 50.1069518],
  Омск: [54.9848566, 73.3674517],
  'Ростов-на-Дону': [47.2224566, 39.718803],
  Уфа: [54.734944, 55.9578468],
  Пермь: [58.0102583, 56.2342034],
  Красноярск: [56.0093879, 92.8524806],
  Воронеж: [51.6593332, 39.1969229],
  Волгоград: [48.7070042, 44.5170339],
  Краснодар: [45.0401604, 38.9759647],
  Саратов: [51.533557, 46.034257],
  Тюмень: [57.1529744, 65.5344099],
  Тольятти: [57.1529744, 65.5344099],
  Ижевск: [56.852738, 53.2114896],
  Сочи: [43.5854551, 39.7231548],
  Владивосток: [43.1164904, 131.8823937],
  'Южно-Сахалинск': [46.9591631, 142.737976],
};

export default cities;
